import React from 'react'
import { Button, Typography, TextField, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom'
import { url } from './constant'
import logo from "../logo512.svg";

import Alert from './Alert'

class ExpertSignUp extends React.Component{
    constructor(){
        super()
        this.state = {
                       expertData: {
                        expertRewardBalance:0,
                        expertRewardPaid:0,
                        expertRewardTotal:0

                       }
        }
    }

    createExpert = (e) =>{
        e.preventDefault();


        fetch(url+'/createExpert',{
            method: 'POST',
            body: JSON.stringify(this.state.expertData)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    expertData: {}
                })            
                this.setState({      
                    setSnack:true,
                    message:'Submitted successfully',
                    msgstatus:"success"
                })  
              
                setTimeout(()=>{
                    this.props.history.push("/")
                    window.location.reload();
                }, 3000)                
            }
        })
    }

 

    handlePromoChange = (e) => {      

             if(e.target.name=='expertUserID'){
            let temp = e.target.value;

    const re = /^[a-z0-9]+$/;
                  if(!re.test(temp)){
         
                              this.setState({      
                    setSnack:true,
                    message:'Please enter valid username.',
                    msgstatus:"error"
                })   

                   //   expertData: {...this.state.expertData, [e.target.name] : ""}
  this.setState({ 
            expertData: {...this.state.expertData, [e.target.name] : ""}
        });

                   return;

        }}


        this.setState({ 
            expertData: {...this.state.expertData, [e.target.name] : e.target.value}
        });
    }

    selectArea = (v)=>{
        this.setState({
            expertData: {...this.state.expertData, "res_id": v["res_id"], "res_name": v["res_name"] },            
        })
    }


    
    handleClose = e =>{
        this.setState({
          setSnack: null 
        })
    }

    render(){

        return(


            // <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "90vh", alignItems: 'center' }} >
            <div style={{display: 'flex', justifyContent: "center" , width: "100%", paddingTop: "20px",paddingBottom: "20px" }}>
            <form onSubmit={(e)=> this.createExpert(e, this.state.edit)} style={{width: "45%"}}>  
              <img src={logo} style={{width: 150}} /><br/> 
           
                <Typography variant='h5'   style={{ margin: "20px 0px"}}   >Expert / Affiliate Onboarding - Sign Up</Typography>  
            
           
            {promoField.map((item, index) =>
                <TextField  
                    style={{marginBottom: 14}}
                    fullWidth
                    key={index}                              
                    label={item.label}                            
                    required
                    onChange={this.handlePromoChange}
                    onExit={this.handlePromoChange}
                    multiline={item.multiline}
                    rows={item.noOfRows}
                    variant="outlined"
                    type={item.type}
                    name={item.name}
                    value={this.state.expertData[item.name]}                            
                />
            )}           
            <div style={{display: 'flex', justifyContent: "flex-full" , width: "100%" }}>
                <Button type='submit' variant="contained" color="primary" fullWidth >
                    Submit
                </Button>     
            </div>    
                   
            </form>

            <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>
        )
    }
}

const promoField = [
    {
        "label": "Name",
        "name":"expertName",
        "type": "text",
        "noOfRows": 1
    },
    {
        "label": "email",
        "name":"expertEmail",
        "type": "text",        
    },
    {
        "label": "Phone",
        "name":"expertPhone",
        "type": "text"        
    },
    {
        "label": "Address",
        "name":"expertAddress",
        "type": "text",
        "multiline": true,
        "noOfRows": 4        
    },
    {
        "label": "City",
        "name":"expertCity",
        "type": "text"        
    },
    {
        "label": "Instagram Link",
        "name":"expertInsta",
        "type": "url"        
    },
    {
        "label": "Username / Profile ID (Only a-z 0-9)",
        "name":"expertUserID",
        "type": "text"        
    }
    ,
    {
        "label": "Password",
        "name":"expertPassword",
        "type": "password"        
    },
    // {
       
    //     "name":"expertRewardBalance",
    //     "type": "hidden",
    //     // "value":0,        
    // },
    // {
       
    //     "name":"expertRewardPaid",
    //     "type": "hidden",
    //     // "value":0,       
    // }
    // ,
    // {
      
    //     "name":"expertRewardTotal",
    //     "type": "hidden",
    //     // "value":0,    
    // }

]

export default withRouter(ExpertSignUp);