import React from 'react'
import { 
    Button, 
    Typography, 
    Card, 
    Grid,
    CardActions,
    Dialog, 
    DialogActions, 
    DialogContent,
    DialogTitle,     
    Snackbar, 
    TextField 
} from '@material-ui/core';

import { url, produrl, promos } from './constant'
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Alert from './Alert'

class AddNewPromotions extends React.Component{
    constructor(){
        super();
        this.state={
            promoFormData: {
                'res_id': "",
                'res_name':"",
                'promoCode': '',                
                'used': [],
                'type': '',
                'rating': "",
                'promoImage': '',
                'couponType': 'Main coupon',
                'deleted': false
            },
            crop: {
                unit: '%',
                width: 50,
                aspect: 1 / 1,
            },
            buttonDisable: true,
            openImageCrop: false,
            promoTypes: [],
            fetchRestaurantData: [],
            imageUploading: false,
            openReviewDialog: false,
            couponType: ['Main coupon', 'Sub coupon']
        }
    }

    componentDidMount(){
        this.getPromoType()
    }

    getPromoType=()=>{
        fetch(url+"/getPromoType",{
            method: "GET"            
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){
                this.setState({
                    promoTypes: data['response']
                })
            }        
        })
    }

    handleSelectChange = (e) =>{        
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "type" : e.target.value}
        });
    }

    addNewPromo = (e) =>{
        e.preventDefault();
        this.state.promoFormData.promoCode = uuidv4().toString().split('-')[0].toUpperCase()

        this.setState({
            openReviewDialog: true
        })        
    }

    addPromotion = () =>{
        fetch(url+'/addPromo',{
            method: 'POST',
            body: JSON.stringify(this.state.promoFormData)
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({      
                    setSnack:true,
                    message:'Promotion added successfully',
                    msgstatus:"success",                    
                })
                setTimeout(()=>{
                    window.location.reload()
                }, 300)
            }
        })
    }

    handlePromoChange = (e) => {                 
        this.setState({ 
            promoFormData: {...this.state.promoFormData, [e.target.id] : e.target.value}
        });
    }
    
    selectArea = (v)=>{        
        this.setState({
            promoFormData: {...this.state.promoFormData, "res_id": v["res_id"], "res_name": v["res_name"] },            
        })
    }

    fetchRestaurant = e => {
        this.setState({
            fetchRestaurantData: []
        })
        fetch(url+'/fetchRestaurantAutoComplete',{
            method: "POST",
            body: JSON.stringify({"name": {"$regex": e.target.value, $options:'i'}})
        })
        .then(res=> res.json())
        .then((data)=>{
            if(data['status'] == "okay"){                   
                data['response'].map(item =>{
                    this.setState({                        
                        fetchRestaurantData:  [...this.state.fetchRestaurantData , {"res_id": item['_id']['$oid'], "res_name": item['name']}]
                    })
                })                
            }
        })
    }

    handleImageChange = (e) =>{

        const reader = new FileReader();
        if(reader && e.target.files[0]){
            reader.addEventListener('load', () =>
                this.setState({ image: reader.result, openImageCrop: true })
            );
            reader.readAsDataURL(e.target.files[0]);
        }        
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };
    
    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };
    
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {            
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
          );          
          this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob(blob => {
            if (!blob) {
              return;
            }
            blob.name = fileName;
            this.setState({
                blob: blob
            })
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, 'image/jpeg');
        });
    }

    uploadCropedImage = () =>{
        this.setState({
            imageUploading: true
        })
        let form_data = new FormData();      

        form_data.append('image', this.state.blob);                   
        
        fetch(produrl+"/getImage",{
            method: "POST",
            body: form_data
        })
        .then((res)=> res.json())
        .then((data)=>{        
            this.setState({
                openImageCrop: false,
                imageUploading: false,
                buttonDisable: false,                
                promoFormData: {...this.state.promoFormData, "promoImage": data['data']},            
            })
        })
    }

    selectPromoType = (data) => {
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "type" : data['fValue']}
        });
    }

    selectCouponType = (data) => {
        this.setState({ 
            promoFormData: {...this.state.promoFormData, "couponType" : data}
        });
    }

    handleClose = e =>{
        this.setState({
          setSnack: false 
        })
    }

    render(){
        const { fetchRestaurantData, buttonDisable, openImageCrop, couponType, openReviewDialog, imageUploading, promoTypes, crop, image, promoFormData } = this.state;                        
        return(
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "100%", alignItems: 'center' }} >
            <form onSubmit={(e)=> this.addNewPromo(e, this.state.edit)} style={{width: "45%"}}>             
                <Typography variant='h5' align="center" >Add new promotions</Typography>
                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={fetchRestaurantData}
                    id="area"
                    onChange={(e, value) => this.selectArea(value)}
                    getOptionLabel={option => option.res_name}                               
                    renderInput={params => (
                    <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Select restaurant"
                        placeholder="Select restaurant"
                        onChange={this.fetchRestaurant}
                    />
                    )}
                />

                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={promoTypes} 
                    id='type'
                    onChange={(e, value) => this.selectPromoType(value)}
                    getOptionLabel={option => option.fName}                               
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                            variant="outlined"
                            label="Promotion type"
                            placeholder="Promotion type"                        
                        />
                    )}
                />

                <Autocomplete
                    style={{width: "100%", margin: "20px 0px"}}                            
                    options={couponType} 
                    defaultValue={this.state.promoFormData.couponType}
                    id='type'
                    onChange={(e, value) => this.selectCouponType(value)}
                    getOptionLabel={option => option}                               
                    renderInput={params => (
                        <TextField
                            required
                            {...params}
                            variant="outlined"
                            label="Coupon type"                            
                        />
                    )}
                />
                
                {promoField.map(item =>
                    <TextField
                        style={{margin: '10px 0px'}}
                        autoFocus
                        required
                        id={item.name}
                        label={item.label}
                        type={item.type}
                        variant='outlined'
                        fullWidth                    
                        placeholder={item.placeholderLabel}
                        InputLabelProps={{
                            shrink: item.shrink
                        }}
                        onChange={this.handlePromoChange}                 
                    />
                )}    

                { promoFormData['promoImage'] ?
                    <Card style={{width: 280, margin: 10,   boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>                                                                                            
                        <img src={promos+promoFormData.promoImage} style={{width: 280, height: 300}}/>
                        <CardActions>
                            <div style={{display: 'flex', flex: 1}}>                                
                                <Button  size="small" color="primary" variant="outlined" onClick={()=> document.getElementById('promoImage').click()}>
                                    Change
                                </Button>
                            </div>
                        </CardActions>
                    </Card> :
                    <Button   
                        size="small" 
                        color="primary" 
                        style={{margin: "15px 0px"}} 
                        variant="outlined" 
                        onClick={()=> document.getElementById('promoImage').click()}
                    >
                        Add image
                    </Button> 
                }
                <input
                    hidden
                    type="file"
                    style={{padding: 10, margin: 0,}}
                    required
                    id="promoImage"
                    accept="image/png, image/jpeg" 
                    name="promoImage"
                    onChange={this.handleImageChange}                
                />
                <div style={{display: 'flex', justifyContent: "flex-end" , width: "100%" }}>
                    <Button type='submit' variant="outlined" color="secondary" disabled={buttonDisable} >
                        review & Add 
                    </Button>     
                </div>
            </form>

            <Dialog maxWidth  open={openImageCrop} >
                <DialogTitle>Resize image</DialogTitle>
                <DialogContent style={{ minHeight: 600, width: 600 }}>                     
                <ReactCrop
                    src={image}
                    style={{ minHeight: 600, width: 600 }}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                />
                </DialogContent>                    
                <DialogActions>
                    <Button variant='outlined' size='small' color='secondary' onClick={this.uploadCropedImage} >
                        {imageUploading ? "Uploading" : "Save"}
                    </Button>
                    <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openImageCrop: false })} >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog maxWidth  open={openReviewDialog} >
                <DialogTitle>Review & add promotion</DialogTitle>
                <DialogContent style={{ minHeight:400, width: 600 }}>
                    <Grid container spacing={1} justify='center' >
                        <Grid item xs></Grid>  
                        <Grid item xs>
                            <Card style={{width: 200, height: 200, margin: 10,  boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.4)"}}>                                                                                     
                                <img src={promos+promoFormData.promoImage} style={{width: 280, height: 300}}/>
                            </Card>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                    <div style={{ margin: '0 auto', width: 320 }} >                 
                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >Promotion title</Typography>
                            <Typography variant="h6" > {promoFormData['title']}</Typography>
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >Promotion code</Typography>
                            <Typography variant="h6" > {promoFormData['promoCode']}</Typography>
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >Promotion type</Typography>
                            <Typography variant="h6" > {promoFormData['type']}</Typography>
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >No of promotions</Typography>
                            <Typography variant="h6" > {promoFormData['total_no_of_coupons']}</Typography>
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >Avail from</Typography>
                            <Typography variant="h6" > {promoFormData['availFrom']}</Typography>
                        </div>

                        <div style={{display: 'flex', width: '100%', justifyContent:'space-between', marginTop: 10}} >
                            <Typography variant="subtitle1" >Expires on</Typography>
                            <Typography variant="h6" > {promoFormData['valid_upto']}</Typography>
                        </div>
                    </div>
                </DialogContent>                    
                <DialogActions>
                    <Button variant='outlined' size='small' color='secondary' onClick={this.addPromotion} >
                        Add promotion
                    </Button>
                    <Button variant='outlined' size='small' color='primary' onClick={()=> this.setState({ openReviewDialog: false })} >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={this.state.setSnack}
                autoHideDuration={2000}
                onClose={this.handleClose}
            >
                <Alert onClose={this.handleClose} severity={this.state.msgstatus}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            </div>
        )
    }
}

const promoField = [
    {
        "label": "Promo title",
        "name":"title",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false
    },
    {
        "label": "Offer",
        "name":"price",
        "type": "text",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "placeholderLabel": "Eg. Free or 10% offer"
    },
    {
        "label": "Total number of promotions",
        "name":"total_no_of_coupons",
        "type": "number",
        "noOfRows": 1,
        "multiline": false,
        "required": false
    },
    {
        "label": "Available from date",
        "name":"availFrom",
        "type": "date",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "shrink": true
    },
    {
        "label": "Expiry date",
        "name":"valid_upto",
        "type": "date",
        "noOfRows": 1,
        "multiline": false,
        "required": false,
        "shrink": true
    },
]


export default withRouter(AddNewPromotions)