import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import store from 'store';
import { url } from './constant';



// const useStyles = makeStyles({
//   root: {
//     maxWidth: 345,
//     color:'#E81D33',
//     margin:20,
  
//   },
//   media: {
//     height: 440,
//   },
// });
// const {  data } = this.state
// getdata();
// const getdata=()=>{
//   const username = store.get("log_in_user");
//   this.setState({
//       loading: true
//   })
//   fetch(url+"/getapprovedExpertReviews",{
//       method: "POST" ,   
//       headers: {'Content-Type':'application/json'},
//       body: JSON.stringify({'addedby':username})        
//   })
//   .then(res => res.json())
//   .then((data)=>{
//       if(data['status']=='okay'){                
//           this.setState({
//               data: data['response'],
//               loading: false,
          
//           })
//       }else{                
//           this.setState({        
//               setSnack:true,
//               loading: false,
//               message:'Something went wrong',
//               msgstatus:"error"
//           })
//       }
//   })
//   .catch(err =>{
//       this.setState({        
//           setSnack:true,
//           loading: false,
//           message:'Something went wrong',
//           msgstatus:"error"
//       })
//   })
// }

class MediaCard extends React.Component{
  constructor(){
      super()
      this.state = {
          data: [],
          pending:[],
          myrestaurants:[],
          loading: true                    
      }
  }
 
  componentDidMount(){
      this.getdata()
      this.getpendingdata()
      this.getexpertaddedrestaurants()
  }
  getdata=()=>{
    const username = store.get("log_in_user");
    this.setState({
        loading: true
    })
    fetch(url+"/getapprovedExpertReviews",{
        method: "POST" ,   
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({'addedby':username} )        
    })
    .then(res => res.json())
    .then((data)=>{
        if(data['status']=='okay'){                
            this.setState({
                data: data['response'],
                loading: false,
            
            })
        }else{                
            this.setState({        
                setSnack:true,
                loading: false,
                message:'Something went wrong',
                msgstatus:"error"
            })
        }
    })
    .catch(err =>{
        this.setState({        
            setSnack:true,
            loading: false,
            message:'Something went wrong',
            msgstatus:"error"
        })
    })
}

getpendingdata=()=>{
const username = store.get("log_in_user");
this.setState({
    loading: true
})
fetch(url+"/getpendingsingleExpertReviews",{
    method: "POST" ,   
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({'addedby':username} )        
})
.then(res => res.json())
.then((data)=>{
    if(data['status']=='okay'){                
        this.setState({
          pending: data['response'],
            loading: false,
        
        })
    }else{                
        this.setState({        
            setSnack:true,
            loading: false,
            message:'Something went wrong',
            msgstatus:"error"
        })
    }
})
.catch(err =>{
    this.setState({        
        setSnack:true,
        loading: false,
        message:'Something went wrong',
        msgstatus:"error"
    })
})
}


getexpertaddedrestaurants=()=>{
  const username = store.get("log_in_user");
  this.setState({
      loading: true
  })
  fetch(url+"/getexpertrestaurants",{
      method: "POST" ,   
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify({'addedby':username} )        
  })
  .then(res => res.json())
  .then((data)=>{
      if(data['status']=='okay'){                
          this.setState({
            myrestaurants: data['response'],
              loading: false,
          
          })
      }else{                
          this.setState({        
              setSnack:true,
              loading: false,
              message:'Something went wrong',
              msgstatus:"error"
          })
      }
  })
  .catch(err =>{
      this.setState({        
          setSnack:true,
          loading: false,
          message:'Something went wrong',
          msgstatus:"error"
      })
  })
  }

render(){
 
    const {  data, pending, myrestaurants } = this.state
  return (
    
         <div style={{ width: "100%", display: 'flex', justifyContent: 'center', height: "90vh", alignItems: 'center' }} >
    <Card >
      <CardActionArea>
        <CardMedia
          style={{height: 440, width: 345}}
          image="expert1.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {myrestaurants.length} Restaurants
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
          out of 2000<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions>
  
        <Button href="/addnewrestaurant" size="small" color="primary">
          Add Restaurant
        </Button>
      </CardActions>
    </Card>
     <Card style={{ margin:20 }}>
      <CardActionArea>
        <CardMedia
          style={{height: 440, width: 345}}
          image="expert2.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            { pending.length } Reviews
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
    out of 200<br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions>
    <Button href="/AddExpertReview" size="small" color="primary">
         Add Review
        </Button>
      </CardActions>
    </Card>
     <Card >
      <CardActionArea>
        <CardMedia
            style={{height: 440, width: 345}}
          image="expert3.jpg"
          title="Contemplative Reptile"
        />
        <CardContent >
          <Typography gutterBottom variant="h5" component="h2">
           {data.length} Reviews Approved
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
        3 under reivew<br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button href="mailto:admin@bestfoodfinders.com" size="small" color="primary">
          Contact Support
        </Button>
       
      </CardActions>
    </Card>
    </div>
  );
}
}

export default MediaCard;
