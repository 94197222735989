import React from 'react'
import { Button, Typography, TextField, Snackbar, Grid } from '@material-ui/core';
import store from 'store';
import Alert from './Alert'
import { url } from './constant';

class MyProfile extends React.Component{
    constructor(){
        super()
        this.state = {
            data: [],
            loading: true                    
        }
    }
   
    componentDidMount(){
        this.getdata()
    }

    getdata=()=>{
        const username = store.get("log_in_user");
        this.setState({
            loading: true
        })
        fetch(url+"/getExpertProfile",{
            method: "POST" ,   
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({'expertUserID':username} )        
        })
        .then(res => res.json())
        .then((data)=>{
            if(data['status']=='okay'){                
                this.setState({
                    data: data['response'],
                    loading: false,
                
                })
            }else{                
                this.setState({        
                    setSnack:true,
                    loading: false,
                    message:'Something went wrong',
                    msgstatus:"error"
                })
            }
        })
        .catch(err =>{
            this.setState({        
                setSnack:true,
                loading: false,
                message:'Something went wrong',
                msgstatus:"error"
            })
        })
    }

    render(){
        const {  data } = this.state
        return(


            <div style={{ width: "100%", display: 'flex', height: "90vh", margin:"20px" }} > 
            <div style={{width: "30%", display: 'flex', flexDirection: "column" }}>
               <Typography style={{ margin: 10}} variant='h5'>My Profile</Typography>
               </div>
               <div style={{width: "40%", display: 'flex', flexDirection: "row" }}>
   
               <div style={{marginTop: 40, width: "100%", display: 'flex', flexDirection: "column" }}>
          
            <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="Name" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertName}                           
                />
                 <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="Email" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertEmail}                           
                />
                   <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="Mobile" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertPhone}                               
                />
               
                 <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="Address" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertPhone}                              
                />
                    <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="City" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertCity}                            
                />
                    <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="Instagram" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertInsta}                             
                />
                    <TextField  
                    style={{marginBottom: 14}}
                    fullWidth                            
                    label="UserID" 
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true, }}
                    value={data.expertUserID}                              
                />
                 </div>
            </div>
         
            </div>
        )
    }
}



export default MyProfile;