import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from 'store'
import Login from './Components/Login';
import MainPage from './Components/MainPage'
import ExpertDashboard from './Components/ExpertDashboard'
import ExpertSignUp from './Components/ExpertSignUp'



ReactDOM.render(
  <React.StrictMode>
  <Router>    
{/* {console.log(window.location.hash)}  for HashRouter to get current url*/} 
{/* {console.log(window.location.pathname)} for BrowserRouter to get current url */}
    <Route path="/expertsignup" > 
      <ExpertSignUp/>
    </Route>
    
    <Route path="/"> 
      {store.get("log_in") ? 
        store.get("log_in_userAdmin")?
           <MainPage/> 
          :<ExpertDashboard/>
          :null}
          {
            !(store.get("log_in")) && 
            !(store.get("log_in_userAdmin")) && 
            (window.location.pathname!=='/expertsignup')?
            <Login/>:null
          }
    </Route>



  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();